<template>
  <div class="body">
        <div class="body-bg" :style="`background-image: url(${muscleModel.defaultBackgroundImage})`">
          <img v-for="item in muscleModel.muscleImageList" :key="item" :src="item" alt="" class="position" />
          <div class="line-bg position" :style="`background-image: url(${muscleModel.defaultLingImage})`"></div>
        </div>
        <div class="lengend f22 row-end-center">
          <div class="lengend-item row-center-center">
            <div class="icon"></div>
            <p>主要部位</p>
          </div>
          <div class="lengend-item row-center-center">
            <div class="icon light"></div>
            <p>次要部位</p>
          </div>
        </div>
      </div>
</template>

<script>
import userMixin from '@/mixin/userMixin'
export default {
  mixins: [userMixin],
  props: {
    muscleModel: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {

    }
  },
  computed: {},
  async created() {
    await this.$getAllInfo()

  },
  methods: {

  },
}
</script>
<style lang="less" scoped>
.body {
    width: 670px;
    .body-bg {
      width: 100%;
      height: 418px;
      background-size: 100% 100%;
      position: relative;

      .position {
        width: 670px;
        height: 418px;
        background-size: 100% 100%;
        position: absolute;
        left: 0;
      }
    }
    .lengend {
      margin: 50px 0 0;
      .lengend-item {
        margin: 0 0 0 32px;
        .icon {
          width: 18px;
          height: 18px;
          background: #ffde00;
          border-radius: 4px 4px 4px 4px;
          margin: 0 6px 0 0;
          &.light {
            background: #aea664;
          }
        }
      }
    }
  }
</style>
