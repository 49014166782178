<template>
  <div class="container">
    <div class="nav row-between-center">
      <div class="row-start-center">
        <img class="logo" src="https://img.chaolu.com.cn/MINI/coach-feelings/logo.png" alt="" />
        <div>
          <p class="f28 fw6">超鹿运动App</p>
          <p class="f22 grey">可按次付费的健身房</p>
        </div>
      </div>
      <div class="open-button row-center-center fw6 f24">打开App <wx-open-app :extinfo="extinfo"></wx-open-app></div>
    </div>
    <div class="main row-between-start flex-wrap">
      <div class="item" v-for="item in 11"></div>
      <wx-open-app :extinfo="extinfo"></wx-open-app>
    </div>
  </div>
</template>

<script>
import wxOpenApp from '@/components/wx-open-app'

import { openApp } from '@/lib/wx-sdk'
import Vue from 'vue'
// 忽略自定义元素标签抛出的报错
Vue.config.ignoredElements = ['wx-open-launch-app']

export default {
  components: {
    wxOpenApp,
  },
  data() {
    return {
      extinfo: {
        name: '杰尼龟',
      },
    }
  },
  async mounted() {
    openApp()
  },

  methods: {
    // wxerrorFn(e) {
    //     console.log('fail', e)
    //   if (e.detail.errMsg === 'launch:fail') {
    //     console.log('未安装app, 跳转应用宝地址')
    //     // window.location.href = "https://a.app.qq.com/o/simple.jsp?pkgname=xxxx";
    //   } else if(e.detail.errMsg === 'launch:fail_check fail') {
    //     console.log('appid未绑定未关联到微信')
    //   }
    // },

    goOld() {},
  },
}
</script>
<style lang="less" scoped>
*::-webkit-scrollbar {
  display: none;
}
.container {
  background: #F5F5F5;
  * {
    box-sizing: border-box;
  }

  .nav {
    width: 750px;
    height: 128px;
    background: #ffffff;
    border-bottom: 1px solid #eeeeee;
    padding: 0 36px;
    position: sticky;
    z-index: 9;
    top: 0;
    .logo {
      width: 76px;
      margin: 0 20px 0 0;
    }
    .open-button {
      width: 146px;
      height: 56px;
      background: #FFDE00;
      border-radius: 4px 4px 4px 4px;
    }
  }
  .main {
    padding: 32px;
    position: relative;
    .item {
      width: 330px;
      height: 426px;
      background: #FFFFFF;
      border-radius: 12px 12px 12px 12px;
      margin: 0 0 28px;
    }
  }
}
.grey {
  color: #6c727a;
}
</style>
