<template>
  <div>
    <nav-bar ref="head" :show-back="true" :header-back="newAppBack" />
    <div class="container">
      <!-- 头部 -->
      <div class="banner-box">
        <img :src="planDetail.coverImage" alt="" class="banner" />
        <div class="mask row-start-end">
          <h2 class="f54 fw6">{{ planDetail.name }}</h2>
        </div>
      </div>
      <p v-if="planDetail.isDel" class="del-tip f32 fw6">该计划已被删除</p>
      <!-- 计划概览 -->
      <div class="info-box row-between-center">
        <div class="info-item">
          <p class="f40 fw6"><span class="puhui">{{ planDetail.cycle }}</span> <span class="f22 opacity9">周</span></p>
          <p class="p2 f24">计划周期</p>
          <img src="https://img.chaolu.com.cn/ACT/training-record/time-icon.png" alt="" class="icon">
        </div>
        <div class="info-item">
          <p class="f40 fw6"><span class="puhui">{{ planDetail.frequency }}</span> <span class="f22 opacity9">练/周</span></p>
          <p class="p2 f24">训练频率</p>
          <img src="https://img.chaolu.com.cn/ACT/training-record/pinci-icon.png" alt="" class="icon">
        </div>
        <div class="info-item special">
          <p class="f28 fw6"><span>{{ planDetail.levelName }}</span> <span class="f22 opacity9"></span></p>
          <p class="p2 f24">计划难度</p>
          <img src="https://img.chaolu.com.cn/ACT/training-record/11-icon.png" alt="" class="icon">
        </div>
      </div>
      <!-- 计划详情 -->
      <div class="detail-box">
        <p class="detail-t f28 row-start-start"><img src="https://img.chaolu.com.cn/ACT/training-record/yalin-icon.png" alt="" class="icon"> {{ planDetail.utensilNameList }}</p>
        <p class="detail-c f28" v-html="planDetail.introduce"></p>
      </div>
      <!-- 计划课程 -->
      <div class="course-box">
        <h3 class="f32 fw6">计划课程</h3>
        <div class="course-list">
          <div @click="jump(item)" class="course-item row-between-center" v-for="item,index in planDetail.templateList" :key="item.templateId">
            <div class="c-poster flex-none" :style="`background-image: url(${item.backgroundImage})`">
              <!-- <div class="mask"></div> -->
              <p class="index f32 fw6">{{ index + 1 }}</p>
            </div>
            <div class="c-content flex-auto">
              <p class="f30 fw6">{{ item.templateName }}</p>
              <div class="line-1 f20 flex">
                <p class="p1"><span class="fw6 f24">{{ item.motionNum }}</span><span class="opacity9"> 动作</span></p>
                <p class="p1"><span class="fw6 f24">{{ item.groupNum }}</span><span class="opacity9"> 组</span></p>
                <p class="p1"><span class="fw6 f24">{{ item.sumKg }}</span><span class="opacity9"> kg</span></p>
              </div>
              <div class="f22 opacity9 flex">
                <p class="p2 ellipsis">{{ item.labelStr }}</p>
              </div>
            </div>
            <van-icon name="arrow" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import userMixin from '@/mixin/userMixin'
import navBar from '@/components/nav-bar/nav-bar'
import { newAppBack, initBack, appOpenWeb } from '@/lib/appMethod'
import { getParam } from '@/lib/utils'

export default {
  mixins: [userMixin],
  components: {
    navBar,
  },
  data() {
    return {
      planDetail: {}, // 总详情
    }
  },
  computed: {},
  async created() {
    // 初始化
    await this.$getAllInfo()
    initBack()
    // 获取链接参数
    this.exercisePlanId = getParam().exercisePlanId

    this.getPlan()
  },


  methods: {
    newAppBack,

    getPlan() {
      this.$axios.post(this.baseURLApp + '/b/exercise/plan/shareDetail', {
        exercisePlanId: this.exercisePlanId,
        userId: this.userId
      }).then((res) => {
        this.planDetail = res.data
        document.title = this.planDetail.name
        this.planDetail.utensilNameList = res.data.utensilNameList.join(' ')
      })
    },
    jump(item) {
      appOpenWeb(item.templateName, `${window.location.origin}/#/training-record/template?templateId=${item.templateId}&title=${item.templateName}`)
    }
  },
}
</script>
<style lang="less" scoped>
*::-webkit-scrollbar {
  display: none;
}
.container {
  min-height: 100vh;
  background: #242424;
  color: #fff;
  * {
    box-sizing: border-box;
  }
  width: 100%;
  padding: calc(env(safe-area-inset-top)) 0 calc(140px + env(safe-area-inset-bottom));
  .banner-box {
    position: relative;
    display: block;
    .banner {
      width: 100vw;
      height: 100vw;
      object-fit: cover;
    }
    .mask {
      background: linear-gradient(180deg, rgba(36, 36, 36, 0) 0%, #242424 100%);
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      z-index: 1;
      padding: 0 0 48px 40px;
    }
  }
  .detail-box {
    padding: 0 40px;
    margin: 82px 0 0;
    .icon {
      width: 30px;
      height: 30px;
      margin: 6px 16px 0 0;
    }
    .detail-c {
      margin: 72px 0 0;
      white-space: pre-line;
    }
  }
  .del-tip {
    margin: 80px 0 124px;
    text-align: center;
    color: #F03C18;
  }
  .info-box {
    position: relative;
    z-index: 2;
    padding: 0 40px;
    .info-item {
      width: 213px;
      height: 136px;
      padding: 18px 0 0 28px;
      background: rgba(255, 255, 255, 0.08);
      border-radius: 12px 12px 12px 12px;
      position: relative;
      &.special {
        padding: 24px 0 0 28px;
      }
      .p2 {
        margin: 12px 0 0;
        color: rgba(255, 255, 255, .5);
      }
      .icon {
        width: 32px;
        position: absolute;
        right: 24px;
        top: 28px;
      }
    }
  }
  .course-box {
    padding: 0 40px;
    margin: 90px 0 0;
    .course-list {
      margin: 12px 0 0;
      .course-item {
        margin: 36px 0 0;
        padding: 24px;
        background: rgba(255, 255, 255, 0.07);
        border-radius: 12px;
        .c-poster {
          width: 156px;
          height: 156px;
          box-shadow: 0px 2 9px 0px rgba(0, 0, 0, 0.1);
          border-radius: 10px;
          background-image: url(https://chaolucoachwebstatic.chaolu.com.cn/static/img/miniprogram/default-banner.png);
          background-size: cover;
          background-position: center;
          position: relative;
          overflow: hidden;
          .index {
            position: absolute;
            top: 16px;
            left: 16px;
            color: #fff;
          }
          .mask {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            background: linear-gradient(221deg, #90f52b 0%, #cbfc40 100%);
          }
        }
        .c-content {
          margin: 0 26px 0;
          overflow: hidden;
          .line-1 {
            margin: 28px 0;
          }
          .p1 {
            margin: 0 24px 0 0;
          }
          .p2 {
            margin: 0 12px 0 0;
          }
        }
      }
    }
  }
  .opacity9 {
    opacity: 0.9;
  }
}
</style>
